import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Loader from './Loader';
import ComboBox from './input/ComboBox';
import MultiselectComboBox from './input/MultiselectComboBox';

const firebaseConfig = {
    apiKey: 'AIzaSyAPkNokV9jN5fFP8VdAumIKpsGZwQ6zyAM',
    authDomain: 'w3bres.firebaseapp.com',
    projectId: 'w3bres',
    storageBucket: 'w3bres.appspot.com',
    messagingSenderId: '695217763516',
    appId: '1:695217763516:web:2e3acc1f32cc0bf2368cb9',
    measurementId: 'G-L44D8ZJD6B',
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

const rolesComboBox = ['Frontend Developer', 'Backend Developer', 'Smart Contracts Developer', 'Fullstack Developer', 'Designer', 'Artist', 'Mobile Developer'];
const expertiseComboBox = ['Javascript', 'React', 'Node', 'Python', 'Solidity', 'Rust', 'Flutter/Dart', 'Figma', 'Blender', 'Android', 'IOS/Swift'];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Profile(props) {
    const [data, setData] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [loading, setLoading] = useState(true);
    const { pfpLink, setPfpLink } = props;
    const fileInputRef = useRef();

    const setTwitter = (value) => {
        updateDoc(doc(db, 'users', auth.currentUser.uid), {
            twitter: value,
        });
    };

    const updateRole = (role) => {
        if (role) {
            updateDoc(doc(db, 'users', auth.currentUser.uid), {
                role: role,
            });
        }
    };

    const updateExpertise = (expertise) => {
        if (expertise) {
            updateDoc(doc(db, 'users', auth.currentUser.uid), {
                expertise: expertise,
            });
        }
    };

    const setWork = (value) => {
        updateDoc(doc(db, 'users', auth.currentUser.uid), {
            open_to_work: value,
        });
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const setEmail = (value) => {
        if (!validateEmail(value)) {
            setEmailValid(false);
        } else {
            setEmailValid(true);
        }
        updateDoc(doc(db, 'users', auth.currentUser.uid), {
            email: value,
        });
    };

    const setPfp = (value) => {
        updateDoc(doc(db, 'users', auth.currentUser.uid), {
            pfp: value,
        }).then(setPfpLink(value));
        // setData({ ...data, pfp: value });
    };

    const handlePfpChange = (file) => {
        const payload = {
            image: file,
        };
        axios({
            method: 'post',
            url: 'https://api.imgbb.com/1/upload?key=d6fbb5d0e89b515b5b56820c96fe043e',
            data: payload, // you are sending body instead
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            setPfp(response.data.data.url);
        });
    };

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                getDoc(doc(db, 'users', auth.currentUser?.uid)).then((docSnap) => {
                    setData(docSnap.data());
                    setLoading(false);
                });
            }
        });
    }, []);

    if (loading) return <Loader />;
    else
        return (
            <div className="flex w-full h-full px-10 py-6">
                <div className="grid grid-rows gap-6 mr-auto">
                    <div className="text-white text-3xl font-bold">Profile</div>
                    <div className="">
                        <label htmlFor="avatar" className="block text-sm font-medium text-white">
                            Avatar
                        </label>
                        <input
                            onChange={(e) => {
                                handlePfpChange(e.target.files[0]);
                            }}
                            multiple={false}
                            ref={fileInputRef}
                            type="file"
                            hidden
                        />
                        <div className="mt-2 flex items-center">
                            <img className="h-12 w-12 rounded-full overflow-hidden border-2 border-white" src={pfpLink} alt="" />
                            <button
                                onClick={() => fileInputRef.current.click()}
                                type="button"
                                className="ml-5 bg-ochre border border-ochre py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white hover:bg-dark-ochre focus:outline-none focus:ring-2 focus:ring-offset-black focus:ring-offset-2 focus:ring-cadmium-orange"
                            >
                                Change
                            </button>
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-white">ENS</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                                type="text"
                                name="ens"
                                id="ens"
                                disabled
                                className="bg-gray placeholder-sonic-silver text-light-gray focus:ring-davys-gray focus:border-davys-gray block w-full sm:text-sm rounded-md"
                                placeholder={auth.currentUser?.uid}
                            />
                        </div>
                    </div>
                    <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                            <input
                                onChange={(e) => setWork(e.target.checked)}
                                id="work"
                                name="work"
                                type="checkbox"
                                className="bg-gray focus:shadow-none focus:ring-border-0 h-5 w-5 text-jet border-light-gray rounded"
                                defaultChecked={data.work}
                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <label className="font-medium text-white">I'm open to work</label>
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-white">Email</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                defaultValue={data.email}
                                className={classNames(
                                    emailValid ? 'focus:border-davys-gray focus:ring-davys-gray' : 'focus:border-red focus:ring-red',
                                    'bg-gray placeholder-light-gray text-light-gray block w-full  sm:text-sm  rounded-md'
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-white">Twitter</label>
                        <div className="mt-2 flex rounded-md shadow-sm">
                            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-space-silver bg-eerie-black text-sonic-silver sm:text-sm">
                                twitter.com/
                            </span>
                            <input
                                type="text"
                                name="twitter"
                                id="twitter"
                                onChange={(e) => setTwitter(e.target.value)}
                                defaultValue={data.twitter}
                                className="flex-1 min-w-0 block w-full px-3 py-2 bg-gray placeholder-light-gray text-light-gray focus:ring-davys-gray focus:border-davys-gray block w-full sm:text-sm rounded-none rounded-r-md"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-white">Role</label>

                        <ComboBox updateRole={updateRole} people={rolesComboBox} defaultRole={data.role} />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-white">Expertise</label>

                        <MultiselectComboBox updateExpertise={updateExpertise} people={expertiseComboBox} defaultExpertise={data.expertise} />
                    </div>
                </div>
            </div>
        );
}
