/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MailIcon } from '@heroicons/react/solid';

export default function ConnectWalletModal(props) {
    const { connectWalletModal, setConnectWalletModal, signInWithMetaMask, modalLoading } = props;
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={connectWalletModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-jet rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                <div>
                                    <div className="mt-1 text-center sm:mt-1">
                                        <Dialog.Title as="h3" className="text-2xl mb-8 leading-6 font-bold text-white">
                                            Connect account
                                        </Dialog.Title>
                                        {!modalLoading && (
                                            <div>
                                                <button
                                                    type="button"
                                                    onClick={signInWithMetaMask}
                                                    className="inline-flex items-center px-6 py-3 border border-gray shadow-sm text-base font-medium rounded-md text-white bg-gray 
                                            hover:bg-black"
                                                >
                                                    <img className="-ml-1 mr-3 h-6 w-6" src={'https://i.ibb.co/mHgkQ4c/metamask-2.png'} alt="" />
                                                    Connect with MetaMask
                                                </button>
                                            </div>
                                        )}
                                        {modalLoading && (
                                            <div className="loader ease-linear rounded-full border-4 border-t-4 border-eerie-black h-12 w-12 mb-4 mx-auto"></div>
                                        )}
                                        <div className="mt-8 mx-auto">
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex justify-center rounded-md border border-white shadow-sm px-10 py-2 bg-jet text-base font-medium text-white focus:outline-none   sm:mt-0 sm:col-start-1 sm:text-sm"
                                                onClick={() => setConnectWalletModal(false)}
                                                ref={cancelButtonRef}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
