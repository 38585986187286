import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { useState, useEffect, useRef } from 'react';
import { Navigate, useNavigate, useNavigationType } from 'react-router-dom';
import axios from 'axios';
import Loader from './Loader';

const firebaseConfig = {
    apiKey: 'AIzaSyAPkNokV9jN5fFP8VdAumIKpsGZwQ6zyAM',
    authDomain: 'w3bres.firebaseapp.com',
    projectId: 'w3bres',
    storageBucket: 'w3bres.appspot.com',
    messagingSenderId: '695217763516',
    appId: '1:695217763516:web:2e3acc1f32cc0bf2368cb9',
    measurementId: 'G-L44D8ZJD6B',
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();

const apiKey = 'mQetg7mH_mGLzRIPvzkB5-N2XN9auPxR';
const baseURL = `https://eth-rinkeby.alchemyapi.io/nft/v2/${apiKey}/getNFTs/`;
const contractAddr = '0xda7e8A31b8b2bFb9Ca754Ae8EC7c0A973c6d4dD5';

const adventureMapImg = 'https://i.ibb.co/ZSGnVV2/Mask-group-2.png';

export default function Experiencs() {
    const [NFTs, setNFTs] = useState('');
    const [loading, setLoading] = useState(true);
    const [hasNFTs, setHasNFTS] = useState(false);

    const navigate = useNavigate();

    const checkHasNFTs = (response) => {
        if (response?.data?.ownedNfts?.length > 0) {
            console.log(true);
            setHasNFTS(false);
        } else {
            setHasNFTS(false);
        }
    };

    const navMarketplace = () => {
        navigate('/');
    };

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // replace with the wallet address you want to query for NFTs
                const ownerAddr = user.uid;

                var config = {
                    method: 'get',
                    url: `${baseURL}?owner=${ownerAddr}&contractAddresses%5B%5D=${contractAddr}`,
                };
                console.log('config defined');

                axios(config)
                    .then((response) => {
                        setNFTs(response.data);
                        checkHasNFTs(response);
                        setLoading(false);
                    })
                    .catch((error) => console.log(error));
            }
        });
    }, []);

    if (loading) return <Loader />;
    else
        return (
            <div className="flex w-full h-full px-10 py-6">
                <div className="grid grid-rows gap-6 mr-auto">
                    <div className="text-white text-3xl font-bold">Experiences</div>
                    {hasNFTs && (
                        <div>
                            {NFTs.ownedNfts?.map((NFT) => (
                                <div key={NFT.metadata.description} className="grid grid-cols-3">
                                    <img className="inline-block h-16 w-16 rounded-full col-span-1" src={NFT.metadata.image} alt="" />
                                    <div className="text-white col-span-2"> {NFT.metadata.description}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    {!hasNFTs && (
                        <div>
                            <img className="block mx-auto md:max-w-sm brightness-75" src={adventureMapImg} alt="" />
                            <div className="mt-1 text-light-gray text-center text-md font-semibold">Your adventure is yet to begin!</div>
                            <div className="mt-3 text-light-gray text-center text-md  font-normal">
                                Complete
                                <span className="font-bold text-orange-500 cursor-pointer" onClick={navMarketplace}>
                                    {' '}
                                    projects{' '}
                                </span>
                                to get paid, mint work experiences as non-fungible badges, and build your Web3 reputation.
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
}
