import { Fragment, useState } from 'react';
import { Disclosure, Menu, RadioGroup, Switch, Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon, SearchIcon } from '@heroicons/react/solid';
import { MapIcon, SparklesIcon, CreditCardIcon, KeyIcon, MenuIcon, UserCircleIcon, ViewGridAddIcon, XIcon } from '@heroicons/react/outline';
import Profile from './Profile';
import Experiences from './Experiences';
import Portfolio from './Portfolio';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function ProfileMenu(props) {
    const { pfpLink, setPfpLink } = props;
    const [currentPage, setCurrentPage] = useState('Profile');

    const subNavigation = [
        { index: 1, name: 'Profile', href: '#', icon: SparklesIcon, current: currentPage === 'Profile' },
        { index: 2, name: 'Experiences', href: '#', icon: MapIcon, current: currentPage === 'Experiences' },
        // { index: '2', name: 'Portfolio', href: '#', icon: MapIcon, current: currentPage === 'Portfolio' },
    ];

    return (
        <>
            <div className="h-full">
                <main className="max-w-7xl mx-auto mb-10 lg:my-12 lg:px-8 bg-black">
                    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <aside className="py-6 px-6 lg:py-0 lg:px-0 lg:col-span-3 bg-black">
                            <nav className="space-y-1 bg-gray rounded-md">
                                {subNavigation.map((item) => (
                                    <div
                                        key={item.name}
                                        href={item.href}
                                        onClick={() => setCurrentPage(item.name)}
                                        className={classNames(
                                            item.current ? 'bg-eerie-black text-ochre' : 'text-light-gray hover:text-gray-400',
                                            'cursor-pointer group px-4 py-3 flex items-center text-sm font-medium',
                                            item.index === 1 ? 'rounded-t-md' : item.index === 2 ? 'rounded-b-md' : ''
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        <item.icon
                                            className={classNames(item.current ? 'text-orange-500' : 'text-gray-400 ', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6')}
                                            aria-hidden="true"
                                        />
                                        <span className="truncate">{item.name}</span>
                                    </div>
                                ))}
                            </nav>
                        </aside>

                        {/* Content */}
                        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 bg-black">
                            {currentPage === 'Profile' && <Profile pfpLink={pfpLink} setPfpLink={setPfpLink} />}
                            {currentPage === 'Experiences' && <Experiences />}
                            {currentPage === 'Portfolio' && <Portfolio />}
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
