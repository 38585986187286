import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Loader from './Loader';

const firebaseConfig = {
    apiKey: 'AIzaSyAPkNokV9jN5fFP8VdAumIKpsGZwQ6zyAM',
    authDomain: 'w3bres.firebaseapp.com',
    projectId: 'w3bres',
    storageBucket: 'w3bres.appspot.com',
    messagingSenderId: '695217763516',
    appId: '1:695217763516:web:2e3acc1f32cc0bf2368cb9',
    measurementId: 'G-L44D8ZJD6B',
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();

export default function Portfolio() {
    const [loading, setLoading] = useState(false);

    if (loading) return <Loader />;
    else
        return (
            <div className="flex w-screen h-full px-10 py-6">
                <div className="grid grid-rows gap-6 mr-auto">
                    <div className="text-white text-3xl font-bold">Portfolio</div>
                </div>
            </div>
        );
}
