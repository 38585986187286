import { BrowserRouter as Router, Routes, Route, Link, Outlet } from 'react-router-dom';
import { useState } from 'react';
import Landing from './Landing';
import Projects from './Projects';
import Navbar from './Navbar';
import ProjectPage from './ProjectPage';
import ClientDasboard from './ClientDashboard';
import PostProject from './PostProject';
import Profile from './Profile';
import ProfileMenu from './ProfileMenu';
import Hiring from './Hiring';
import Messaging from './Messaging';

function App() {
    const [pfpLink, setPfpLink] = useState('https://i.ibb.co/yRvyrps/Default-pfp.jpg');

    return (
        <Router>
            <Navbar pfpLink={pfpLink} setPfpLink={setPfpLink} />

            <Routes>
                <Route path="/client/dashboard" element={<ClientDasboard />}></Route>
                <Route path="/client/new" element={<PostProject />}></Route>
                <Route path="/directory" element={<Hiring />}></Route>
                <Route path="/profile" element={<ProfileMenu pfpLink={pfpLink} setPfpLink={setPfpLink} />}></Route>
                <Route path="/projects/:id" element={<ProjectPage />}></Route>
                <Route path="/messaging" element={<Messaging />}></Route>
                <Route path="/" element={<Projects />}></Route>
            </Routes>
        </Router>
    );
}

export default App;
