import { Navigate, useNavigate, useNavigationType } from 'react-router-dom';

export default function TasksRow(props) {
    const { name, tasks } = props;

    const navigate = useNavigate();

    return (
        <div>
            <div className="mb-4 text-white text-xl font-bold">{name}</div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 grid-flow-row">
                {tasks?.map((task) => (
                    <div
                        key={task.id}
                        onClick={() => navigate('/projects/' + task.id)}
                        className="relative cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                        <div className="flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={task.imageUrl} alt="" />
                        </div>
                        <div className="flex-1 min-w-0">
                            <span className="absolute inset-0" aria-hidden="true" />
                            <p className="text-sm text-gray-500">{task.client}</p>
                            <p className="text-sm font-medium text-gray-900">{task.name}</p>
                            <p className="text-sm text-gray-500 line-clamp-3">{task.desc}</p>
                            <div
                                style={{ width: 'fit-content' }}
                                className="flex justify-center items-center ml-auto m-1 mt-2 px-2 py-1 border border-blue rounded-full bg-blue text-base text-white font-medium"
                            >
                                <div className="flex-initial max-w-full leading-none text-xs font-normal">{task.compensation}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
