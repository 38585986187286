/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ComboBox from '../input/ComboBox';
import MultiselectComboBox from '../input/MultiselectComboBox';

const rolesComboBox = ['Frontend Developer', 'Backend Developer', 'Smart Contracts Developer', 'Fullstack Developer', 'Designer', 'Artist', 'Mobile Developer'];

const expertiseComboBox = ['Javascript', 'React', 'Node', 'Python', 'Solidity', 'Rust', 'Flutter/Dart', 'Figma', 'Blender', 'Android', 'IOS/Swift'];

export default function NewUserModal(props) {
    const { newUserModal, initializeProfile } = props;
    const [formData, setFormData] = useState({ name: '', email: '', role: '', expertise: [], twitter: '', work: false });
    const cancelButtonRef = useRef(null);

    const updateName = (e) => {
        setFormData({ ...formData, name: e.target.value });
    };

    const updateEmail = (e) => {
        setFormData({ ...formData, email: e.target.value });
    };

    const updateRole = (role) => {
        setFormData({ ...formData, role: role });
    };

    const updateExpertise = (expertise) => {
        setFormData({ ...formData, expertise: expertise });
    };

    const updateTwitter = (e) => {
        setFormData({ ...formData, twitter: e.target.value });
    };

    const updateWork = (e) => {
        setFormData({ ...formData, work: e.target.checked });
    };

    return (
        <Transition.Root show={newUserModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-jet rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                <div>
                                    <div className="mt-1 text-center sm:mt-1">
                                        <Dialog.Title as="h3" className="text-2xl mb-4 leading-6 font-bold text-white">
                                            Set up your profile ✨
                                        </Dialog.Title>
                                        <div className="text-left">
                                            <div>
                                                <div className="mt-2">
                                                    <p className="text-sm text-light-gray">Name</p>
                                                </div>
                                                <div className="mt-2 relative rounded-md shadow-sm">
                                                    <input
                                                        type="text"
                                                        onChange={updateName}
                                                        name="name"
                                                        id="name"
                                                        required
                                                        className="resize-none bg-gray text-light-gray placeholder-sonic-silver shadow-sm focus:ring-light-gray focus:border-light-gray block w-full sm:text-sm rounded-md"
                                                        placeholder="Jack Welsh, bananaslayer99, etc."
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-3">
                                                    <p className="text-sm text-light-gray">Email</p>
                                                </div>
                                                <div className="mt-2 relative rounded-md shadow-sm">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        onChange={updateEmail}
                                                        id="email"
                                                        className="resize-none bg-gray text-light-gray placeholder-sonic-silver shadow-sm focus:ring-light-gray focus:border-light-gray block w-full sm:text-sm rounded-md"
                                                        placeholder="jack@bananas.com"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-3">
                                                    <p className="text-sm text-light-gray">Main role</p>
                                                </div>
                                                <ComboBox updateRole={updateRole} people={rolesComboBox} />
                                            </div>
                                            <div>
                                                <div className="mt-3">
                                                    <p className="text-sm text-light-gray">Expertise</p>
                                                </div>
                                                <MultiselectComboBox updateExpertise={updateExpertise} people={expertiseComboBox} />
                                            </div>
                                            <div>
                                                <div className="mt-3">
                                                    <p className="text-sm text-light-gray">Twitter</p>
                                                </div>
                                                <div className="mt-2 flex rounded-md shadow-sm">
                                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-space-silver bg-eerie-black text-sonic-silver sm:text-sm">
                                                        twitter.com/
                                                    </span>
                                                    <input
                                                        type="text"
                                                        onChange={updateTwitter}
                                                        name="twitter"
                                                        id="twitter"
                                                        className="flex-1 min-w-0 block w-full px-3 py-2 bg-gray text-light-gray rounded-none rounded-r-md focus:ring-light-gray focus:border-light-gray sm:text-sm"
                                                        placeholder="thejackbanana"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-5 ml-0.5 mb-1 relative flex items-start">
                                                    <input
                                                        id="work"
                                                        onChange={updateWork}
                                                        name="work"
                                                        type="checkbox"
                                                        className="bg-gray focus:shadow-none focus:ring-border-0 h-5 w-5 text-eerie-black border-light-gray rounded"
                                                    />
                                                    <div className="ml-3 text-sm">
                                                        <label className="font-medium text-white">I'm open to work</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="w-2/3 md:w-1/2 mx-auto inline-flex justify-center rounded-md border border-blue shadow-sm px-4 py-2 bg-blue text-base font-medium text-white hover:bg-maximum-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue focus:ring-offset-jet sm:col-start-2 sm:text-sm"
                                        onClick={() => initializeProfile(formData)}
                                    >
                                        Let's go!
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
