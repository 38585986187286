import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { doc, getDoc, getDocs, setDoc, getFirestore, collection, updateDoc, where, query } from 'firebase/firestore';
import Loader from './Loader';

const firebaseConfig = {
    apiKey: 'AIzaSyAPkNokV9jN5fFP8VdAumIKpsGZwQ6zyAM',
    authDomain: 'w3bres.firebaseapp.com',
    projectId: 'w3bres',
    storageBucket: 'w3bres.appspot.com',
    messagingSenderId: '695217763516',
    appId: '1:695217763516:web:2e3acc1f32cc0bf2368cb9',
    measurementId: 'G-L44D8ZJD6B',
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default function Hiring() {
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(true);
    const people1 = [
        {
            name: 'Lindsay Walton',
            role: 'Front-end Developer',
            department: 'Optimization',
            ens: '0x2ef1b8007cbfeab588b335fe9f1dfba4181c21ee',
            expertise: ['Member'],
            pfp: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Lindsay Walton',
            role: 'Front-end Developer',
            department: 'Optimization',
            ens: '0x2ef1b8007cbfeab588b335fe9f1dfba4181c21ee',
            expertise: ['Member'],
            pfp: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Lindsay Walton',
            role: 'Front-end Developer',
            department: 'Optimization',
            ens: '0x2ef1b8007cbfeab588b335fe9f1dfba4181c21ee',
            expertise: ['Member'],
            pfp: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Lindsay Walton',
            role: 'Front-end Developer',
            department: 'Optimization',
            ens: '0x2ef1b8007cbfeab588b335fe9f1dfba4181c21ee',
            expertise: ['Member'],
            pfp: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Lindsay Walton',
            role: 'Front-end Developer',
            department: 'Optimization',
            ens: '0x2ef1b8007cbfeab588b335fe9f1dfba4181c21ee',
            expertise: ['Member'],
            pfp: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            name: 'Lindsay Walton',
            role: 'Front-end Developer',
            department: 'Optimization',
            ens: '0x2ef1b8007cbfeab588b335fe9f1dfba4181c21ee',
            expertise: ['Member'],
            pfp: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ];

    const openLink = () => {
        window.open('https://airtable.com/shrTgiXhl0u8STcTu', '_blank');
    };

    useEffect(() => {
        let usersArray = [];

        getDocs(query(collection(db, 'users'))).then((usersSnapshot) => {
            usersSnapshot.docs.forEach((doc) => {
                if (doc.id.includes('0x') && doc.data().role) {
                    console.log(doc.data().name, doc.data().role);
                    usersArray.push({ ...doc.data(), ens: doc.id });
                }
            });
            setPeople(usersArray);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        console.log(people);
    }, [people]);

    if (loading) return <Loader />;
    else
        return (
            <div className="relative">
                <div className="px-10 text-white absolute left-0 right-0 top-0 bottom-0 text-center m-auto w-fit max-w-5xl h-fit z-10">
                    <span className="leading-10 font-semibold text-3xl">Get access to Web3 talent around the world</span>
                    <br />

                    <span className="font-normal text-lg break-normal">
                        Sonder provides you with a hiring platform for the decentralized web. Get access to hundreds of skilled, remote engineers for your
                        full-time position or gig.
                    </span>
                    <br />
                    <div className="my-8" />
                    <div className="mx-auto">
                        <button
                            type="button"
                            onClick={openLink}
                            className="inline-flex ml-auto items-center px-4 py-2 border border-ochre text-base font-medium rounded-md shadow-sm text-white bg-ochre hover:bg-dark-ochre"
                        >
                            Get in touch
                        </button>
                    </div>
                </div>
                <div className="px-4 sm:px-6 lg:px-8 blur-md brightness-75">
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-davys-gray">
                                        <thead className="bg-gray">
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">
                                                    User
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Role
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Expertise
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                                                    Open to Work
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-davys-gray bg-jet">
                                            {people.map((person) => (
                                                <tr key={person.ens}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                        <div className="flex items-center">
                                                            <div className="h-10 w-10 flex-shrink-0">
                                                                <img className="h-10 w-10 rounded-full" src={person.pfp} alt="" />
                                                            </div>
                                                            <div className="ml-4">
                                                                <div className="font-medium text-white">{person.name}</div>
                                                                <div className="text-light-gray">{person.ens}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm ">
                                                        <div className="text-light-gray">{person.role}</div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                                                        {person.expertise?.map((skill) => (
                                                            <span
                                                                key={skill}
                                                                className="inline-flex m-1 rounded-full bg-blue px-2 text-xs font-semibold leading-5 text-white"
                                                            >
                                                                {skill}
                                                            </span>
                                                        ))}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-light-gray">{person.work ? 'Yes' : 'No'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}
