import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { doc, getDoc, getDocs, setDoc, getFirestore, collection, updateDoc, where, query } from 'firebase/firestore';
import Loader from './Loader';
import TasksRow from './TasksRow';

const firebaseConfig = {
    apiKey: 'AIzaSyAPkNokV9jN5fFP8VdAumIKpsGZwQ6zyAM',
    authDomain: 'w3bres.firebaseapp.com',
    projectId: 'w3bres',
    storageBucket: 'w3bres.appspot.com',
    messagingSenderId: '695217763516',
    appId: '1:695217763516:web:2e3acc1f32cc0bf2368cb9',
    measurementId: 'G-L44D8ZJD6B',
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default function Projects() {
    const [projects, setProjects] = useState([]);
    const [internships, setInternships] = useState([]);
    const [contractual, setContractual] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let projectsArray = [];
        let internshipsArray = [];
        let contractualArray = [];

        getDocs(query(collection(db, 'tasks'), where('disabled', '!=', true)))
            .then((tasksSnapshot) =>
                tasksSnapshot.docs.map((doc) => {
                    return { ...doc.data(), id: doc.id };
                })
            )
            .then((tasksList) => {
                console.log(tasksList.length);
                for (var i = 0; i < tasksList.length; i++) {
                    switch (tasksList[i].type) {
                        case 'project':
                            projectsArray.push(tasksList[i]);
                            break;
                        case 'internship':
                            internshipsArray.push(tasksList[i]);
                            break;
                        case 'contractual':
                            contractualArray.push(tasksList[i]);
                            break;
                    }
                }

                setProjects(projectsArray);
                setInternships(internshipsArray);
                setContractual(contractualArray);
                setLoading(false);
            });
    }, []);

    if (loading) return <Loader />;
    else
        return (
            <div className="max-w-screen h-full p-10">
                <TasksRow name="Projects" tasks={projects} />
                <div className="mt-8" />
                <TasksRow name="Internships" tasks={internships} />
                <div className="mt-8" />
                <TasksRow name="Contractual" tasks={contractual} />
            </div>
        );
}
