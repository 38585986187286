/*
Use this: only need string for combobox
*/
import { useState, useEffect } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/outline';
import { Combobox } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const people = [
    { id: 1, name: 'Durward Reynolds' },
    { id: 2, name: 'Kenton Towne' },
    { id: 3, name: 'Therese Wunsch' },
    { id: 4, name: 'Benedict Kessler' },
    { id: 5, name: 'Katelyn Rohan' },
];

export default function MultiselectComboBox(props) {
    const { people, updateExpertise, defaultExpertise } = props;
    const [query, setQuery] = useState('');
    const [selectedPerson, setSelectedPerson] = useState();
    const [selectedValues, setSelectedValues] = useState([]);

    const removeSelectedValues = (value) => {
        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter((item) => item !== value));
        }
    };

    useEffect(() => {
        updateExpertise(selectedValues);
    }, [selectedValues]);

    useEffect(() => {
        if (defaultExpertise) {
            setSelectedValues(defaultExpertise);
        }
    }, []);

    const filteredPeople =
        query === ''
            ? people
            : people.filter((person) => {
                  return person.toLowerCase().includes(query.toLowerCase());
              });

    return (
        <Combobox as="div" value={selectedValues} onChange={setSelectedValues} multiple>
            <div className="relative mt-1">
                {selectedValues.length > 0 && (
                    <div className="flex flex-wrap">
                        {selectedValues.map((value) => (
                            <div
                                key={value}
                                style={{ width: 'fit-content' }}
                                onClick={() => removeSelectedValues(value)}
                                className="cursor-pointer flex justify-center items-center m-1 pl-3 pr-2 py-1 border border-blue rounded-full bg-blue text-base text-white font-medium"
                            >
                                <div className="flex flex-initial items-center max-w-full leading-none text-sm font-bold ">
                                    {value}
                                    <XIcon className="ml-1 h-3 w-3 text-gray-100 align-middle" aria-hidden="true" />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className="relative mt-1">
                    <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-gray text-white py-2 pl-3 pr-10 shadow-sm focus:ring-light-gray focus:border-light-gray sm:text-sm"
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <SelectorIcon className="h-5 w-5 text-sonic-silver" aria-hidden="true" />
                    </Combobox.Button>
                </div>
                {filteredPeople.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-scroll rounded-md bg-gray py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredPeople.map((person) => (
                            <Combobox.Option
                                key={person}
                                value={person}
                                className={({ active }) =>
                                    classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-jet text-white' : 'text-white')
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames('block truncate', selected && 'font-semibold')}>{person}</span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-indigo-600'
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
}
