import { useState, useEffect, Fragment, useRef } from 'react';
import { Menu, Popover, Transition } from '@headlessui/react';
import { BellIcon, MenuIcon, XIcon, MailIcon } from '@heroicons/react/outline';
import detectEthereumProvider from '@metamask/detect-provider';
import { getAuth, signInWithCustomToken, onAuthStateChanged, signOut } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { doc, getDoc, getDocs, setDoc, getFirestore, collection, updateDoc, where, query, arrayUnion } from 'firebase/firestore';
import { Navigate, useNavigate, useNavigationType } from 'react-router-dom';
import ConnectWalletModal from './modals/ConnectWalletModal';
import NewUserModal from './modals/NewUserModal';

const firebaseConfig = {
    apiKey: 'AIzaSyAPkNokV9jN5fFP8VdAumIKpsGZwQ6zyAM',
    authDomain: 'w3bres.firebaseapp.com',
    projectId: 'w3bres',
    storageBucket: 'w3bres.appspot.com',
    messagingSenderId: '695217763516',
    appId: '1:695217763516:web:2e3acc1f32cc0bf2368cb9',
    measurementId: 'G-L44D8ZJD6B',
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

const user = {
    name: 'Chelsea Hagon',
    email: 'chelsea.hagon@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Navbar(props) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [provider, setProvider] = useState('');
    const [connectWalletModal, setConnectWalletModal] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [newUserModal, setNewUserModal] = useState(false);
    const [newMessages, setNewMessages] = useState(false);

    const { pfpLink, setPfpLink } = props;

    const navigate = useNavigate();
    const buttonRef = useRef();

    const initializeProfile = (formData) => {
        console.log(formData);
        updateDoc(doc(db, 'users', auth.currentUser.uid), formData).then(setNewUserModal(false));
    };

    const POSTRequest = async (data, url) => {
        console.log('sending post', data);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        });
        const responseData = await response.json();
        return responseData;
    };

    const toHex = (stringToConvert) => {
        return stringToConvert
            .split('')
            .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
            .join('');
    };

    const trimEthAddress = (address) => {
        return address.substring(0, 4) + '...' + address.slice(-3);
    };

    const approveLogIn = (newUser) => {
        setLoggedIn(true);
        setModalLoading(false);
        setConnectWalletModal(false);

        if (newUser) {
            navigate('/');
            setNewUserModal(true);
        } else {
            navigate('/profile');
        }
    };

    const connectWallet = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.open('https://metamask.app.link/dapp/beta.usesonder.com');
        } else {
            setConnectWalletModal(true);
        }
    };

    const signInWithMetaMask = async () => {
        let ethereum;
        if (provider) {
            try {
                console.log('Ethereum successfully detected!');
                setModalLoading(true);
                ethereum = provider;
                //get account
                await ethereum.request({ method: 'eth_requestAccounts' });
                let nonceData = JSON.stringify({
                    address: ethereum.selectedAddress,
                });
                const nonceResponse = await POSTRequest(nonceData, 'https://us-central1-w3bres.cloudfunctions.net/getNonceToSign');
                console.log('response', nonceResponse);
                let newUser = nonceResponse.newUser;
                const sig = await ethereum.request({
                    method: 'personal_sign',
                    params: [`0x${toHex(nonceResponse.nonce)}`, ethereum.selectedAddress],
                });
                let sigData = JSON.stringify({
                    address: ethereum.selectedAddress,
                    signature: sig,
                });
                const authResponse = await POSTRequest(sigData, 'https://us-central1-w3bres.cloudfunctions.net/verifySignedMessage ');
                await signInWithCustomToken(auth, authResponse.token);
                console.log('signed in', auth.currentUser);

                // In order to create an account, user must enter relevant details, otherwise account isn't created
                // await the response of nonceResponse, if user doesn't already exist, a modal should pop up during approveLoginIn
                approveLogIn(newUser);
            } catch (err) {
                console.log(err);
                setModalLoading(false);
            }
        } else {
            // if the provider is not detected, detectEthereumProvider resolves to null
            console.error('Please install MetaMask!');
        }
    };

    const disconnect = () => {
        signOut(auth).then(() => {
            navigate('/');
        });
    };

    const navProfile = () => {
        navigate('/profile');
    };

    const navMarketplace = () => {
        navigate('/');
    };

    const navHiring = () => {
        navigate('/directory');
    };

    const navMessaging = () => {
        navigate('/messaging');
    };

    const isMarketplace = () => {
        if (window.location.pathname == '/' || window.location.pathname.includes('/projects')) {
            return true;
        } else {
            return false;
        }
    };

    const isHiring = () => {
        if (window.location.pathname.includes('/directory')) {
            return true;
        } else {
            return false;
        }
    };

    const navigation = [
        { name: 'Marketplace', onClick: navMarketplace, current: isMarketplace() },
        { name: 'Hiring', onClick: navHiring, current: isHiring() },
    ];

    const userNavigation = [
        { name: 'Your Profile', onClick: navProfile },
        { name: 'Disconnect', onClick: disconnect },
    ];

    useEffect(() => {
        detectEthereumProvider().then((provider) => {
            setProvider(provider);
        });
    }, []);

    useEffect(() => {
        if (newUserModal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [newUserModal]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setLoggedIn(true);
                getDoc(doc(db, 'users', user.uid)).then((docSnap) => {
                    setPfpLink(docSnap.data().pfp);
                });
            } else {
                setLoggedIn(false);
            }
        });
    }, []);

    return (
        <>
            {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
            <Popover
                as="header"
                className={({ open }) => classNames(open ? 'fixed inset-0 z-40 overflow-y-auto' : '', 'bg-gray py-4 shadow-sm lg:static lg:overflow-y-visible')}
            >
                {({ open }) => (
                    <>
                        <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="relative flex justify-between xl:grid xl:grid-cols-6 lg:gap-8">
                                <div className="flex md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                                    <div className="flex-shrink-0 flex items-center">
                                        <div onClick={navMarketplace}>
                                            <img className="cursor-pointer block h-6 w-auto" src="https://i.ibb.co/ScNDyFP/SONDER.png" alt="Sonder" />
                                        </div>
                                    </div>

                                    <div className="hidden lg:flex space-x-4 ml-7 ">
                                        {navigation.map((item) => (
                                            <div
                                                key={item.name}
                                                onClick={() => {
                                                    item.onClick();
                                                }}
                                                className={classNames(
                                                    item.current ? 'bg-rich-black text-white' : 'text-light-gray hover:bg-jet hover:text-white',
                                                    'cursor-pointer px-3 py-2 rounded-md text-sm font-semibold'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
                                    {/* Mobile menu button */}
                                    <Popover.Button ref={buttonRef} className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-light-gray ">
                                        <span className="sr-only">Open menu</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Popover.Button>
                                </div>
                                <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                                    {loggedIn && (
                                        <div className="cursor-pointer ml-5 flex-shrink-0 bg-gray block my-auto rounded-full p-1 text-gray-400 hover:text-gray-500 ">
                                            <span className="sr-only">View notifications</span>
                                            <span className="block relative" onClick={navMessaging}>
                                                <MailIcon className="h-6 w-6" aria-hidden="true" />
                                                <span
                                                    className={classNames(
                                                        newMessages ? 'bg-cadmium-orange ring-2 ring-gray' : '',
                                                        'absolute top-0 right-0 block h-1.5 w-1.5 rounded-full  '
                                                    )}
                                                />
                                            </span>
                                        </div>
                                    )}
                                    {/* Profile dropdown */}
                                    {loggedIn && (
                                        <Menu as="div" className="flex-shrink-0 relative ml-5">
                                            <div>
                                                <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-white">
                                                    <span className="sr-only">Open user menu</span>
                                                    <img className="h-8 w-8 rounded-full" src={pfpLink} alt="" />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                                                    {userNavigation.map((item) => (
                                                        <Menu.Item key={item.name}>
                                                            {({ active }) => (
                                                                <div
                                                                    onClick={item.onClick}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100' : '',
                                                                        'cursor-pointer block py-2 px-4 text-sm text-gray'
                                                                    )}
                                                                >
                                                                    {item.name}
                                                                </div>
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    )}

                                    {!loggedIn && (
                                        <button
                                            type="button"
                                            onClick={connectWallet}
                                            className="ml-6 inline-flex items-center px-4 py-2 border border-ochre text-sm font-medium rounded-md shadow-sm text-white bg-ochre hover:bg-dark-ochre focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-gray focus:ring-cadmium-orange"
                                        >
                                            Connect Account
                                        </button>
                                    )}
                                    {loggedIn && (
                                        <button
                                            type="button"
                                            className="cursor-default ml-6 inline-flex items-center px-4 py-2 border border-ochre text-sm font-medium rounded-md shadow-sm text-white bg-ochre "
                                        >
                                            {trimEthAddress(auth.currentUser.uid)}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>

                        <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
                            <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                                {navigation.map((item) => (
                                    <div
                                        key={item.name}
                                        onClick={() => {
                                            item.onClick();
                                            buttonRef.current?.click();
                                        }}
                                        aria-current={item.current ? 'page' : undefined}
                                        className={classNames(
                                            item.current ? 'bg-rich-black' : 'hover:bg-jet',
                                            'block rounded-md py-2 px-3 text-white text-base font-medium cursor-pointer'
                                        )}
                                    >
                                        {item.name}
                                    </div>
                                ))}
                            </div>
                            <div className="border-t  pt-4 pb-3">
                                <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                                    {!loggedIn && (
                                        <div>
                                            <button
                                                type="button"
                                                onClick={connectWallet}
                                                className="inline-flex items-center px-4 py-2 border border-ochre text-sm font-medium rounded-md shadow-sm text-white bg-ochre hover:bg-dark-ochre focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-gray focus:ring-cadmium-orange"
                                            >
                                                Connect Account
                                            </button>
                                        </div>
                                    )}
                                    {loggedIn && (
                                        <div>
                                            <div className="ml-2">
                                                <div className="text-base font-medium text-white">{trimEthAddress(auth.currentUser.uid)}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {loggedIn && (
                                    <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                                        <div
                                            onClick={() => {
                                                navMessaging();
                                                buttonRef.current?.click();
                                            }}
                                            className="block cursor-pointer rounded-md py-2 px-3 text-base font-medium text-white hover:bg-jetx"
                                        >
                                            Messages
                                        </div>
                                        {userNavigation.map((item) => (
                                            <div
                                                key={item.name}
                                                onClick={() => {
                                                    item.onClick();
                                                    buttonRef.current?.click();
                                                }}
                                                className="block cursor-pointer rounded-md py-2 px-3 text-base font-medium text-white hover:bg-jet"
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </Popover.Panel>
                        <ConnectWalletModal
                            connectWalletModal={connectWalletModal}
                            setConnectWalletModal={setConnectWalletModal}
                            signInWithMetaMask={signInWithMetaMask}
                            modalLoading={modalLoading}
                        />

                        <NewUserModal initializeProfile={initializeProfile} newUserModal={newUserModal} />
                    </>
                )}
            </Popover>
        </>
    );
}
