/* This example requires Tailwind CSS v2.0+ */
import { StarIcon, LocationMarkerIcon, CheckIcon } from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import { Navigate, useNavigate, useNavigationType } from 'react-router-dom';
import ConfirmModal from './modals/ConfirmModal';
import { ethers } from 'ethers';
import abi from '../utils/TokenContract.json';
import SuccessModal from './modals/SuccessModal';
import PostProject from './PostProject';

const positions = [
    {
        id: 1,
        title: '0x94b166e4AcE5954FdC256e340571D2DC071CaA92',
        type: 'Full-time',
        location: 'Canada',
        completed: '9',
        rating: '4.7',
    },
];
const { ethereum } = window;
const compensation = '0.001';

export default function ClientDasboard() {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [openSuccessModal, setSuccessModal] = useState(false);
    const contractAddress = '0x12631e4A2BE52e37a7bA090B7533b96e25c6868B';
    const contractABI = abi.abi;

    const tx = {
        from: ethereum.selectedAddress,
        value: ethers.utils.parseEther(compensation),
        gasLimit: ethers.utils.hexlify(0x10000), // 100000
        gasPrice: 5000000000, //5 gwei
    };

    const execFunc = async () => {
        // mint contract from server

        // sign contract

        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const tokenContract = new ethers.Contract(contractAddress, contractABI, signer);

            const sendTxn = await tokenContract.sendPayment(tx);
            console.log('Mining...', sendTxn.hash);

            await sendTxn.wait();
            console.log('Mined -- ', sendTxn.hash, sendTxn);
            setOpenModal(false);
            setSuccessModal(true);
        } else {
            setOpenModal(false);
            console.log('ethereum object does not exist');
        }
    };

    return (
        <div className="m-8">
            <ConfirmModal open={openModal} setOpen={setOpenModal} execFunc={execFunc} />
            <SuccessModal
                open={openSuccessModal}
                setOpen={setSuccessModal}
                title="Transaction successful"
                desc="Your transaction has begun! Upon task completion the
            compensation will be paid to the contractor."
            />
            <div className="flex w-full">
                <button
                    type="button"
                    onClick={() => {
                        navigate('/client/new');
                    }}
                    className="ml-auto inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Post Project
                </button>
            </div>
            <div className="mb-4 text-white text-xl font-bold">Neural Style Transfer Project - {compensation} ETH</div>

            <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul role="list" className="divide-y divide-gray-200">
                    {positions.map((position) => (
                        <li key={position.id}>
                            <div className="px-4 py-4 sm:px-6">
                                <div className="flex items-center justify-between">
                                    <p className="text-sm font-medium text-indigo-600 truncate">{position.title}</p>
                                    <div className="ml-2 flex-shrink-0 flex">
                                        <div className="ml-2 flex-shrink-0 flex">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setOpenModal(true);
                                                }}
                                                className="inline-flex items-center float-right px-2 py-1 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Accept Candidate
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 sm:flex sm:justify-between">
                                    <div className="sm:flex">
                                        <p className="flex items-center text-sm text-gray-500">
                                            <CheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            {position.completed} Projects Completed
                                        </p>
                                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                            <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            {position.location}
                                        </p>
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                        <StarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        <p>{position.rating}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
