/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
export default function ApplyModal(props) {
    const { open, setOpen, uid, application, setApplication, fields, save } = props;
    // const [applicationArray, setApplicationArray] = useState('');
    const cancelButtonRef = useRef(null);
    const updateApplicationArray = (id, value) => {
        // create array with fields
        setApplication({ ...application, [id]: value });
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-jet rounded-lg px-4 pt-5 pb-1 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                <div>
                                    <div className="mt-1 text-center sm:mt-1">
                                        <Dialog.Title as="h3" className="text-2xl mb-4 leading-6 font-bold text-white">
                                            Apply to task
                                        </Dialog.Title>
                                        <div className="text-left">
                                            <div className="mt-2">
                                                <p className="text-sm text-light-gray">Your ENS</p>
                                            </div>
                                            <div className="mt-2 relative rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="ens"
                                                    id="ens"
                                                    disabled
                                                    className="bg-gray placeholder-sonic-silver text-light-gray focus:ring-davys-gray focus:border-davys-gray block w-full sm:text-sm border-gray rounded-md"
                                                    placeholder={uid}
                                                />
                                            </div>

                                            {fields.map((field, index) => (
                                                <div key={field.field_question}>
                                                    <div className="mt-5">
                                                        <p className="text-sm text-light-gray">{field.field_question}</p>
                                                    </div>
                                                    <div className="mt-2 relative rounded-md shadow-sm">
                                                        {field.field_type == 'textfield' && (
                                                            <input
                                                                type="text"
                                                                onChange={(e) => updateApplicationArray(field.field_id, e.target.value)}
                                                                name={'field' + index}
                                                                id={'field' + index}
                                                                className="resize-none bg-gray text-light-gray placeholder-sonic-silver shadow-sm focus:ring-light-gray focus:border-light-gray block w-full sm:text-sm rounded-md"
                                                                placeholder={field.field_placeholder}
                                                                defaultValue={application?.[field.field_id]}
                                                            />
                                                        )}
                                                        {field.field_type == 'textarea' && (
                                                            <textarea
                                                                rows={4}
                                                                onChange={(e) => updateApplicationArray(field.field_id, e.target.value)}
                                                                name={'field' + index}
                                                                id={'field' + index}
                                                                className="resize-none bg-gray text-light-gray placeholder-sonic-silver shadow-sm focus:ring-light-gray focus:border-light-gray block w-full sm:text-sm rounded-md"
                                                                placeholder={field.field_placeholder}
                                                                defaultValue={application?.[field.field_id]}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-blue shadow-sm px-4 py-2 bg-blue text-base font-medium text-white hover:bg-maximum-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue focus:ring-offset-jet sm:col-start-2 sm:text-sm"
                                        onClick={() => save()}
                                    >
                                        Submit
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-white shadow-sm px-4 py-2 bg-jet text-base font-medium text-white focus:outline-none   sm:mt-0 sm:col-start-1 sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
