export default function PostProject() {
    return (
        <div className="flex w-screen h-full p-10">
            <div className="grid grid-rows gap-4 mr-auto">
                <div className="text-white text-2xl font-bold">New Task</div>
                <div>
                    <label className="block text-sm font-medium text-white">Task name</label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="email"
                            id="email"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Your amazing project"
                        />
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-white">Task description</label>
                    <div className="mt-1">
                        <textarea
                            rows={4}
                            name="comment"
                            id="comment"
                            className="resize-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Timelines, examples, etc."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
