import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Navigate, useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { doc, getDoc, getFirestore, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import ApplyModal from './modals/ApplyModal';
import Loader from './Loader';
import TasksRow from './TasksRow';

const firebaseConfig = {
    apiKey: 'AIzaSyAPkNokV9jN5fFP8VdAumIKpsGZwQ6zyAM',
    authDomain: 'w3bres.firebaseapp.com',
    projectId: 'w3bres',
    storageBucket: 'w3bres.appspot.com',
    messagingSenderId: '695217763516',
    appId: '1:695217763516:web:2e3acc1f32cc0bf2368cb9',
    measurementId: 'G-L44D8ZJD6B',
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

export default function Projects() {
    const params = useParams();
    const taskId = params.id;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [project, setProject] = useState({});
    const [application, setApplication] = useState('');
    const [fields, setFields] = useState([]);
    const save = () => {
        // If task doesn't already exist, create
        updateDoc(doc(db, 'users', auth.currentUser.uid), {
            [taskId]: application,
        });
        setOpenModal(false);
    };

    useEffect(() => {
        getDoc(doc(db, 'tasks', taskId)).then((taskSnapshot) => {
            setProject(taskSnapshot.data());
            let mappedFields = taskSnapshot.data();
            setFields(taskSnapshot.data().fields);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        console.log('use effect');
        onAuthStateChanged(auth, (user) => {
            if (user) {
                getDoc(doc(db, 'users', auth.currentUser?.uid)).then((docSnap) => {
                    setApplication(docSnap.data()[taskId]);
                    console.log(docSnap.data());
                });
            }
        });
    }, [openModal]);
    if (loading) return <Loader />;
    else
        return (
            <div className="w-screen h-full p-10">
                <ApplyModal
                    open={openModal}
                    setOpen={setOpenModal}
                    uid={auth.currentUser?.uid}
                    application={application}
                    setApplication={setApplication}
                    fields={fields}
                    save={save}
                />
                <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-5">
                        <div className="mb-2 text-white text-xl font-bold">{project.name}</div>
                        <div className="flex items-start mb-4">
                            <img className="h-6 w-6 rounded-full overflow-hidden border-2 border-white" src={project.imageUrl} alt="" />

                            <div className="ml-3 text-white text-md">{project.client}</div>
                        </div>
                        <div className="text-white text-lg">{project.desc}</div>
                        <div className="mb-10" />
                        <div className="mb-3 text-white text-xl font-bold">Compensation</div>
                        <div
                            style={{ width: 'fit-content' }}
                            className="flex justify-center items-center mr-auto m-1 mt-2 px-4 py-2 border border-blue rounded-full bg-blue 
                        text-base text-white font-medium"
                        >
                            <div className="flex-initial max-w-full leading-none text-lg font-normal">{project.compensation}</div>
                        </div>
                        <div className="mb-10" />
                        <div className="mb-2 text-white text-xl font-bold">Applications Close</div>
                        <div className="text-white text-lg">{project.close_date}</div>
                    </div>
                    <div className="col-span-1">
                        {auth.currentUser && (
                            <button
                                type="button"
                                onClick={() => {
                                    setOpenModal(true);
                                }}
                                className="inline-flex items-center float-right px-6 py-3 border border-blue text-base font-medium 
                        rounded-md text-white bg-blue hover:bg-maximum-blue focus:outline-none focus:ring-2
                        focus:ring-offset-black focus:ring-offset-2 focus:ring-blue"
                            >
                                {application ? 'Edit Application' : 'Apply'}
                            </button>
                        )}
                        {!auth.currentUser && (
                            <button
                                type="button"
                                className="cursor-default inline-flex items-center float-right px-4 py-2 text-xs md:text-base md:px-6 md:py-3 border border-davys-gray text-base font-medium 
                        rounded-md text-white bg-davys-gray"
                            >
                                Login to Apply
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
}
